/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// material-ui
import { useTheme, styled } from '@mui/material/styles'
import { Avatar, Box, ButtonBase, Card, CircularProgress, Grid, InputAdornment, OutlinedInput, Popper } from '@mui/material'

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'

// project imports
import useReduxAction from 'hooks/useReduxAction'
import useSetState from 'hooks/useSetState'
import Transitions from 'ui-component/extended/Transitions'
import AvatarButton from 'ui-component/AvatarButton'
import Results from './components/Results'

// assets
import { IconSearch, IconX } from '@tabler/icons'
import { shouldForwardProp } from '@mui/system'

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '99%',
  top: '-55px !important',
  padding: '0 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}))

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: 434,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: 250,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: 4,
    background: '#fff',
  },
}))

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  '&:hover': {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
  },
}))

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ loading, popupState, results, setState, showResults, value }) => {
  const theme = useTheme()

  return (
    <>
      <OutlineInputStyle
        id="input-search-header"
        value={value}
        onChange={(e) => setState({ searchValue: e.target.value })}
        onFocus={() => setState({ showResults: value ? true : false })}
        onBlur={() => setState({ showResults: false })}
        placeholder="Search"
        startAdornment={
          <InputAdornment position="start">
            <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress size={18} sx={{ color: theme.palette.orange.dark }} />
            ) : (
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: '12px' }} onClick={() => setState({ searchValue: '', currentValue: '' })}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      background: theme.palette.orange.light,
                      color: theme.palette.orange.dark,
                      '&:hover': {
                        background: theme.palette.orange.dark,
                        color: theme.palette.orange.light,
                      },
                    }}
                    {...bindToggle(popupState)}
                  >
                    <IconX stroke={1.5} size="1.3rem" />
                  </Avatar>
                </ButtonBase>
              </Box>
            )}
          </InputAdornment>
        }
        aria-describedby="search-helper-text"
        inputProps={{ 'aria-label': 'weight' }}
        sx={{ position: 'relative' }}
      />
      {showResults && <Results searchResults={results} />}
    </>
  )
}

MobileSearch.propTypes = {
  loading: PropTypes.bool,
  popupState: PopupState,
  results: PropTypes.array,
  setState: PropTypes.func,
  showResults: PropTypes.bool,
  value: PropTypes.string,
}

// ==============================|| SEARCH INPUT ||============================== //

//state
const defaultState = {
  currentValue: '',
  searchResults: [],
  searchValue: '',
  showResults: false,
}

const SearchSection = () => {
  const theme = useTheme()
  const [filterString, setFilterString] = useState('')
  const [state, setState] = useSetState(defaultState)
  const { currentValue, searchResults, searchValue, showResults } = state

  const entityReducer = useSelector((reduxState) => reduxState.sqlServiceJobsSearch)
  const { result: serviceJobsSearchResults, loading } = entityReducer

  const options = {
    search: filterString,
    includes: 'campaign',
  }

  useReduxAction('sqlServiceJobsSearch', 'searchSqlServiceJobs', options, [filterString], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loading } = entityReducer
      return !loading && !errors.length && filterString
    },
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue) {
        const filter = searchValue.replace(/\s+/g, ' ')
        setFilterString(filter)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])

  useEffect(() => {
    setState({ searchResults: serviceJobsSearchResults, currentValue: searchValue })
  }, [serviceJobsSearchResults])

  useEffect(() => {
    if (searchValue && searchValue === currentValue && !loading) {
      setState({ showResults: true })
    } else setState({ showResults: false })
  }, [searchValue, currentValue, loading])

  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                    <IconSearch stroke={1.5} size="1.2rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                      <Card
                        sx={{
                          background: '#fff',
                          [theme.breakpoints.down('sm')]: {
                            border: 0,
                            boxShadow: 'none',
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs>
                              <MobileSearch
                                currentValue={currentValue}
                                loading={loading}
                                popupState={popupState}
                                results={searchResults}
                                setState={setState}
                                value={searchValue}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <OutlineInputStyle
          id="input-search-header"
          value={searchValue}
          onChange={(e) => setState({ searchValue: e.target.value })}
          onFocus={() => setState({ showResults: searchValue ? true : false })}
          onBlur={() => setState({ showResults: false })}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress size={18} sx={{ color: theme.palette.grey[500] }} />
              ) : searchValue ? (
                <AvatarButton
                  background={theme.palette.primary.main}
                  transparent
                  color={theme.palette.grey[500]}
                  callbacks={{
                    toggleClick: () => setState({ searchValue: '', currentValue: '' }),
                  }}
                >
                  <IconX stroke={1.5} size="1.3rem" />
                </AvatarButton>
              ) : null}
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ 'aria-label': 'weight' }}
          sx={{ position: 'relative' }}
        />
        {showResults && <Results searchResults={searchResults} setState={setState} />}
      </Box>
    </>
  )
}

export default SearchSection
