import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/Loadable'
import ErrorElement from 'routes/ErrorElement'

// page routing
const Redirect = Loadable(lazy(() => import('views/Redirect')))

// ==============================|| MAIN ROUTING ||============================== //

const RedirectRoutes = {
  path: 'redirect/:targetPage',
  element: <Redirect />,
  errorElement: <ErrorElement />,
}

export default RedirectRoutes
