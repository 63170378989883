import PropTypes from 'prop-types'

// material-ui
import { useTheme, styled } from '@mui/material/styles'
import { shouldForwardProp } from '@mui/system'

import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

const ResultStyle = styled(List, { shouldForwardProp })(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 5,
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
  marginLeft: 16,
  maxHeight: 290,
  overflowY: 'auto',
  position: 'absolute',
  width: 434,
  zIndex: 10,
  [theme.breakpoints.down('lg')]: {
    width: 250,
  },
  [theme.breakpoints.down('md')]: {
    width: '87%',
    marginLeft: 4,
    background: '#fff',
  },
}))

const ResultItemStyle = styled(ListItemButton, { shouldForwardProp })(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.primary.light}`,
  '&:last-child': {
    borderBottom: 0,
  },
}))

// ==============================|| Results Item ||============================== //

const Results = ({ searchResults, setState }) => {
  return (
    <ResultStyle component="nav" aria-labelledby="nested-list-subheader">
      {searchResults?.length ? (
        searchResults.map((serviceJob) => (
          <ResultItemStyle
            key={serviceJob.id}
            onMouseDown={(e) => {
              window.open(serviceJob.url, '_blank')
              setState({ showResults: false })
            }}
          >
            <ListItemIcon>
              <HomeOutlinedIcon sx={{ fontSize: 20 }} />
            </ListItemIcon>
            <ListItemText
              primary={serviceJob.campaign.name}
              secondary={
                <Typography component="span" variant="subtitle2" sx={{ display: 'inline' }}>
                  {serviceJob.name}
                </Typography>
              }
            />
          </ResultItemStyle>
        ))
      ) : (
        <ResultItemStyle disabled>
          <ListItemIcon>
            <HomeOutlinedIcon sx={{ fontSize: 20 }} />
          </ListItemIcon>
          <ListItemText primary="No Campaigns found" />
        </ResultItemStyle>
      )}
    </ResultStyle>
  )
}

Results.propTypes = {
  searchResults: PropTypes.array,
  setState: PropTypes.func,
}

export default Results
